<script setup lang="ts">
import type { AtomsMessageShortProps } from './AtomsMessageShort.props'
import { iconsMapAtomsMessage } from './AtomsMessageShort.iconsMapping'

defineProps<AtomsMessageShortProps>()
</script>

<template>
  <div class="gap-xxs p-xs md:px-sm bg-primitives-grey-50 flex items-center">
    <component
      :is="iconsMapAtomsMessage[icon]"
      v-if="icon && iconsMapAtomsMessage[icon]"
      class="h-4 w-4 basis-auto"
      aria-hidden="true"
    />
    <p class="text-book-7 md:text-book-6 basis-full">{{ text }}</p>
    <div v-if="$slots['default']" class="gap-xxs flex flex-nowrap items-center">
      <slot />
    </div>
  </div>
</template>
