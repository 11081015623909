import IconsCreditCard from '~icons/icons/CreditCard.svg'
import IconsKlarna from '~icons/icons/Klarna.svg'
import IconsPayPalColor from '~icons/icons/PayPalLogo.svg'
import IconsReturn from '~icons/icons/Return.svg'
import IconsReturns from '~icons/icons/Returns.svg'
import IconsShippingAndReturn from '~icons/icons/ShippingAndReturns.svg'
import IconsInfo from '~icons/icons/Info.svg'

export const iconsMapAtomsMessage: Record<string, string> = {
  shipping: IconsShippingAndReturn,
  return: IconsReturn,
  returns: IconsReturns,
  card: IconsCreditCard,
  paypal: IconsPayPalColor,
  klarna: IconsKlarna,
  info: IconsInfo,
}
